.accordion-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-content: flex-start;
    /* height: 100%; */
}

.question-container {
    padding: 12px 36px;
}

.question-section {
    background: white;
    border: none;
    border-radius: 12px;
    box-shadow: 0 10px 30px rgb(0 0 0 / 10%);
    cursor: pointer;
    max-width: 460px;
    padding: 12px;
}

.question-align {
    align-items: center;
    display: flex;
    min-height: 10px;
    text-align: left;
}

.question-align h4 {
    margin-left: 8px;
}

.question-style {
    font-family: 'Roboto';
    font-family: Roboto;
    font-size: 18px;
    font-weight: 700;
    line-height: 140%;
    color: #212121;
}

.question-icon {
    background: none;
    border: none;
    color: #212121;
    cursor: pointer;
    font-size: 22px;
    margin-left: auto;
    margin-right: 8px;
}

.rotate {
    transform: rotate(45deg);
}

.answer-divider {
    border-top: 1px solid lightgray;
}

.answer {
    margin-left: 8px;
    margin-right: 8px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.6s ease;
    text-align: left;
    font-family: 'Roboto';
    font-family: Roboto;
    font-size: 16px;
    font-weight: 400;
    line-height: 150%;
    color: #424242;
}

p {
    white-space: pre-wrap;
}
